import React from "react";
import PropTypes from "prop-types";

import SiteHead from "../components/sitehead";
import Header from "../components/header";
import Footer from "../components/footer";

import "normalize.css";

import * as styles from "./layout.module.css";

const Layout = ({ title, onMouseDown, onTouchStart, children }) => {
  return (
    <React.Fragment>
      <SiteHead title={title} />
      <Header />
      <main // eslint-disable-line jsx-a11y/no-noninteractive-element-interactions
        className={styles.main}
        onMouseDown={onMouseDown}
        onTouchStart={onTouchStart}
        children={children}
      />
      <Footer />
    </React.Fragment>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
