import React from "react";

import isBrowser from "../util/isBrowser";

function getIsMobile() {
  if (!isBrowser) return false;

  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );
}

export const useIsMobile = () => {
  const [isMobile, setIsMobile] = React.useState(getIsMobile());

  React.useEffect(() => {
    function handleResize() {
      setIsMobile(getIsMobile());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return isMobile;
};
