import React from "react";
import cx from "classnames";
import { useIntl } from "react-intl";

import { useIsMobile } from "../hooks/mobile";
import { useResponsive } from "../hooks/responsive";
import * as jeeliz from "../util/jeelizIntegrator";

import Anchor from "./Anchor";

import * as styles from "./navigation.module.css";
import * as h from "./hamburger.module.css";

const Navigation = (props) => {
  const isMobile = useIsMobile();
  const { isSmallScreen } = useResponsive();

  const showMobileNav = isMobile || isSmallScreen;

  return (
    <div className={cx(styles.navigation, { [styles.small]: isSmallScreen })}>
      {showMobileNav ? <MobileNav {...props} /> : <DesktopNav {...props} />}
    </div>
  );
};

export default Navigation;

const DesktopNav = ({ urls }) => {
  const intl = useIntl();

  return (
    <React.Fragment>
      <Anchor href={urls.home}>
        {intl.formatMessage({
          id: "home-link",
          defaultMessage: "Etusivu",
        })}
      </Anchor>
      <Anchor href={urls.reservation}>
        {intl.formatMessage({
          id: "schedule-appointment",
          defaultMessage: "Ajanvaraus",
        })}
      </Anchor>
      <Anchor href={urls.chat}>
        {intl.formatMessage({
          id: "open-chat",
          defaultMessage: "Avaa chat",
        })}
      </Anchor>
      <Anchor href={urls.feedback}>
        {intl.formatMessage({
          id: "feedback-link",
          defaultMessage: "Anna palautetta",
        })}
      </Anchor>
      <Anchor href={urls.newsletter}>
        {intl.formatMessage({
          id: "newsletter-link",
          defaultMessage: "Tilaa uutiskirje",
        })}
      </Anchor>
    </React.Fragment>
  );
};

const MobileNav = (props) => {
  const [isOpen, setIsOpen] = React.useState(false);

  const handleToggle = () => {
    setIsOpen(!isOpen);
    jeeliz.togglePause(!isOpen);
  };

  return (
    <React.Fragment>
      <button
        className={cx(h.hamburger, h.hamburgerCollapse, {
          [h.isActive]: isOpen,
        })}
        type="button"
        onClick={handleToggle}
      >
        <span className={h.hamburgerBox}>
          <span className={h.hamburgerInner}></span>
        </span>
      </button>
      {isOpen && (
        <div className={styles.menu}>
          <DesktopNav {...props} />
        </div>
      )}
    </React.Fragment>
  );
};
