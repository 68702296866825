import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";

import { useSiteMetadata } from "../hooks/sitemeta";

function SiteHead({ lang, title }) {
  const siteMetadata = useSiteMetadata();

  const metaDescription = siteMetadata.description;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title ? `${title} | ${siteMetadata.title}` : siteMetadata.title}
      meta={[
        {
          name: "viewport",
          content:
            "width=device-width, initial-scale=1, shrink-to-fit=no,user-scalable=no",
        },
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: siteMetadata.title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: siteMetadata.title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ]}
    />
  );
}

SiteHead.defaultProps = {
  lang: "fi",
  title: null,
};

SiteHead.propTypes = {
  lang: PropTypes.string,
  title: PropTypes.string,
};

export default SiteHead;
