const threejsURL = "/libs/threejs/v112/three.min.js";

const scriptURLs = [
  "/libs/GLTFLoader.js",
  "/libs/RGBELoader.js",
  "/libs/facefilter/dist/jeelizFaceFilter.js",
  "/libs/facefilter/helpers/JeelizResizer.js",
  "/libs/facefilter/helpers/JeelizThreejsHelper.js",
];

const NNCpath = "/libs/facefilter/dist/";

const isBrowser = typeof window === "object";

let THREE;
let JEEFACEFILTERAPI;
let JeelizThreeHelper;
let JeelizResizer;
let THREECAMERA;
let sceneLoadedCallback;
let detectCallback;
let errorCallback;

let scriptsLoaded = false;
let JeelizResizerDeclared = false;
let JeelizIntialized = false;

function cacheableSrc(src, libURLs = []) {
  const cacheable = libURLs.find(({ relativePath = "" }) =>
    src.includes(relativePath)
  );
  return cacheable ? cacheable.publicURL : src;
}

function cachebaleNNCpath(defaultPath, libURLs = []) {
  const filename = "NNC.json";
  const cacheable = libURLs.find(({ relativePath = "" }) =>
    relativePath.includes(filename)
  );
  return cacheable ? cacheable.publicURL.replace(filename, "") : defaultPath;
}

export function importScripts(libURLs, callback = null) {
  if (!isBrowser) return;

  if (!callback) {
    console.error("jeelizIntegrator.importScripts(): 'callback' not specified");
    return;
  }

  if (scriptsLoaded) {
    console.log("Scripts already loaded");
    callback();
    return;
  }

  let loaded = 0;
  const script = document.createElement("script");
  script.type = "text/javascript";
  script.src = cacheableSrc(threejsURL, libURLs);
  const headScript = document.getElementsByTagName("script")[0];
  headScript.parentNode.insertBefore(script, headScript);
  script.addEventListener("load", () => {
    loaded += 1;
    scriptURLs.forEach((src) => {
      src = cacheableSrc(src, libURLs);
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.src = src;
      const headScript = document.getElementsByTagName("script")[0];
      headScript.parentNode.insertBefore(script, headScript);
      script.addEventListener("load", () => {
        loaded += 1;
        if (loaded === scriptURLs.length + 1) {
          scriptsLoaded = true;
          callback();
        }
      });
    });
  });
}

export function init(
  libURLs,
  canvasId = "ar-canvas",
  _sceneLoadedCallback = null,
  _detectCallback = null,
  _errorCallback = (errorCode) => {
    console.error(errorCode);
  }
) {
  if (!isBrowser) return;

  THREE = window.THREE;
  JEEFACEFILTERAPI = window.JEEFACEFILTERAPI;
  JeelizThreeHelper = window.JeelizThreeHelper;
  JeelizResizer = window.JeelizResizer;

  if (!_sceneLoadedCallback) {
    console.error("jeelizIntegrator: 'sceneLoadedCallback' not specified");
    return;
  }

  if (!_detectCallback) {
    console.error("jeelizIntegrator: 'detectCallback' not specified");
    return;
  }

  sceneLoadedCallback = _sceneLoadedCallback;
  detectCallback = _detectCallback;
  errorCallback = _errorCallback;

  if (JeelizResizerDeclared) {
    initFaceFilter(libURLs, canvasId);
    return;
  }

  return JeelizResizer.size_canvas({
    canvasId,
    callback: initFaceFilter.bind(null, libURLs, canvasId),
  });
}

export function destroy() {
  if (!isBrowser || !JEEFACEFILTERAPI) return;

  return JEEFACEFILTERAPI.destroy();
}

export function togglePause(isPause, isShutOffVideo) {
  if (!isBrowser || !JEEFACEFILTERAPI) return;

  return JEEFACEFILTERAPI.toggle_pause(isPause, isShutOffVideo);
}

export function toggleSlow(isSlow) {
  if (!isBrowser || !JEEFACEFILTERAPI) return;

  return JEEFACEFILTERAPI.toggle_slow(isSlow);
}

async function initFaceFilter(libURLs, canvasId, _, videoSettings) {
  if (!isBrowser) return;

  JeelizResizerDeclared = true;

  if (JeelizIntialized) {
    await JEEFACEFILTERAPI.destroy();
    JeelizIntialized = false;
  }

  return JEEFACEFILTERAPI.init({
    videoSettings,
    followZRot: true,
    animateDelay: 17,
    canvasId,
    NNCpath: cachebaleNNCpath(NNCpath, libURLs),
    maxFacesDetected: 1,
    callbackReady: function (errCode, spec) {
      JeelizIntialized = true;
      if (errCode) {
        errorCallback(errCode);
        return;
      }
      initThreeScene(spec);
    },
    callbackTrack: function (detectState) {
      JeelizThreeHelper.render(detectState, THREECAMERA);
    },
  });
}

function initThreeScene(spec) {
  if (!isBrowser) return;

  const threeStuffs = JeelizThreeHelper.init(spec, detectCallback);

  // improve WebGLRenderer settings:
  threeStuffs.renderer.toneMapping = THREE.ACESFilmicToneMapping;
  threeStuffs.renderer.outputEncoding = THREE.sRGBEncoding;

  // CREATE THE CAMERA:
  THREECAMERA = JeelizThreeHelper.create_camera();

  THREE.Cache.enabled = true;

  sceneLoadedCallback(THREE, threeStuffs, JeelizThreeHelper);
}
