import { useStaticQuery, graphql } from "gatsby";

export const useSiteMetadata = () => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            urls {
              home
              reservation
              feedback
              newsletter
              store
              chat
            }
          }
        }
      }
    `
  );

  return site.siteMetadata;
};
