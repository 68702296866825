import React from "react";

import { useSiteMetadata } from "../hooks/sitemeta";

import * as styles from "./footer.module.css";

const Footer = () => {
  const siteMeta = useSiteMetadata();
  return (
    <footer className={styles.footer}>
      <div className={styles.copyright}>
        © {new Date().getFullYear()} {siteMeta.author} - Betaversio
      </div>
    </footer>
  );
};

export default Footer;
