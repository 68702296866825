import React from "react";
import cx from "classnames";

import { useResponsive } from "../hooks/responsive";
import { useSiteMetadata } from "../hooks/sitemeta";

import Logo from "./logo";
import Navigation from "./navigation";

import * as styles from "./header.module.css";

const Header = () => {
  const { isSmallScreen } = useResponsive();
  const siteMeta = useSiteMetadata();
  return (
    <header className={cx(styles.header, { [styles.small]: isSmallScreen })}>
      <div className={styles.logoWrapper}>
        <a href="/">
          <Logo />
        </a>
      </div>
      {isSmallScreen ? null : <Navigation urls={siteMeta.urls} />}
    </header>
  );
};

export default Header;
