import React from "react";

import { useWindowDimensions } from "./windowDimensions";

function getResponsiveState(width, extraSmall, small) {
  return {
    isExtraSmallScreen: width <= extraSmall,
    isSmallScreen: width <= small,
  };
}

export const useResponsive = ({ extraSmall = 380, small = 750 } = {}) => {
  const { width } = useWindowDimensions();

  const [state, setState] = React.useState(getResponsiveState);

  React.useEffect(() => {
    setState(getResponsiveState(width, extraSmall, small));
  }, [width, extraSmall, small]);

  return state;
};
